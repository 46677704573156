<template>
  <AuthLayoutContent>
    <template v-slot:left>
      <div class="step d-flex align-items-center mx-auto">
        <div class="w-100">
          <p class="text-danger fw-bold text-capitalize" v-if="getErrors.payment">
            {{ getErrors.payment }}
          </p>

          <h1>{{ $t("Enter the 6 digit code") }}</h1>
          <p class="fw-medium text-gray" v-if="tenant">
            {{ $t("We have sent the code to:") }}
            <span class="text-info">{{ tenant.phone_number }}</span>
          </p>

          <div class="d-flex justify-content-between">
            <input
              type="number"
              maxlength="1"
              class="otp"
              v-model="otp1"
              id="otp1"
              @keyup="focusOTP($event, 1)"
              @keydown="forceSingleCharacter"
            />
            <input
              type="number"
              maxlength="1"
              class="otp"
              v-model="otp2"
              id="otp2"
              @keyup="focusOTP($event, 2)"
              @keydown="forceSingleCharacter"
            />
            <input
              type="number"
              maxlength="1"
              class="otp"
              v-model="otp3"
              id="otp3"
              @keyup="focusOTP($event, 3)"
              @keydown="forceSingleCharacter"
            />
            <div class="desh">-</div>
            <input
              type="number"
              maxlength="1"
              class="otp"
              v-model="otp4"
              id="otp4"
              @keyup="focusOTP($event, 4)"
              @keydown="forceSingleCharacter"
            />
            <input
              type="number"
              maxlength="1"
              class="otp"
              v-model="otp5"
              id="otp5"
              @keyup="focusOTP($event, 5)"
              @keydown="forceSingleCharacter"
            />
            <input
              type="number"
              maxlength="1"
              class="otp"
              v-model="otp6"
              id="otp6"
              @keyup="focusOTP($event, 6)"
              @keydown="forceSingleCharacter"
            />
          </div>

          <p
            class="text-danger fw-bold text-capitalize mt-3 mb-0"
            v-if="getErrors.verify_otp"
          >
            {{ getErrors.verify_otp }}
          </p>
          <p
            class="text-danger fw-bold text-capitalize mt-3 mb-0"
            v-if="getErrors.sending_otp"
          >
            {{ getErrors.sending_otp }}
          </p>

          <div class="mt-3 text-center d-flex justify-content-center">
            <div class="fw-medium text-gray">
              {{ $t("No text received") }}
            </div>
            <div class="ml-2">
              <a href="javascript:void()" class="text-gray fw-bold" @click="sendOTP">
                {{ $t("Resend") }}
              </a>
              <b-spinner
                v-if="getLoadings.sending_otp"
                small
                label="Spinning"
                variant="primary"
                class="ml-2"
              >
              </b-spinner>
            </div>
          </div>

          <div class="w-100 mt-4">
            <button
              class="btn btn-primary btn-block"
              :disabled="otp.length != 7 || getLoadings.verify_otp"
              @click="submit"
            >
              {{ $t("Verify my phone number") }}
              <b-spinner
                v-if="getLoadings.verify_otp"
                small
                label="Spinning"
                variant="white"
              >
              </b-spinner>
            </button>

            <button
              class="btn btn-outline-primary btn-block mt-3"
              @click="$router.push(`/user/flow/1/${tenant.id}`)"
            >
              {{ $t("Change Phone number") }}
              <b-spinner
                v-if="getLoadings.verify_otp"
                small
                label="Spinning"
                variant="white"
              >
              </b-spinner>
            </button>
          </div>
        </div>
      </div>
    </template>
    <template v-slot:right>
      <div class="step__rightSection mx-auto">
        <RightPoints active="1" />
      </div>
    </template>
  </AuthLayoutContent>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import AuthLayoutContent from "@/components/common/AuthLayoutContent.vue";
import RightPoints from "./RightPoints.vue";

export default {
  data() {
    return {
      otp1: "",
      otp2: "",
      otp3: "",
      otp4: "",
      otp5: "",
      otp6: "",
      form: {
        phone_number: "",
      },
      submitting: false,
    };
  },
  components: {
    AuthLayoutContent,
    RightPoints,
  },
  computed: {
    ...mapGetters("loadingError", ["getLoadings", "getErrors"]),
    ...mapGetters("auth", ["user"]),
    ...mapGetters("tenant", ["tenant"]),
    otp() {
      return `${this.otp1}${this.otp2}${this.otp3}-${this.otp4}${this.otp5}${this.otp6}`;
    },
  },
  async created() {
    this.step = +this.$route.params.step;
    let tenant_id = this.$route.params.tenant_id;
    if (tenant_id) {
      await this.getTenant(tenant_id);
    }
  },
  mounted() {
    document.getElementById("otp1").focus();
  },
  methods: {
    ...mapActions("tenant", ["verifyOTP", "sendOTP", "getTenant"]),
    forceSingleCharacter($event) {
      console.log("event", $event.target.value);
      if ($event.target.value.length == 1 && $event.code != "Backspace") {
        $event.preventDefault();
      }
    },
    focusOTP($event, current) {
      if ($event.code == "Backspace") {
        current--;
      } else {
        current++;
      }

      if (current >= 1 && current <= 6) {
        document.getElementById("otp" + current).focus();
      }
    },
    async submit() {
      const data = await this.verifyOTP(this.otp);
      if (data) {
        let router;

        if (this.tenant.expat == 0) {
          if (this.tenant.profession == "entrepreneur") {
            router = "EntrepreneurDocumentUpload";
          } else {
            router = "Digid";
          }
        } else {
          router = "ExpatStep3";
        }

        this.$router.push({
          name: router,
          params: { tenant_id: this.tenant.id },
        });
      }
    },
  },
};
</script>

<style lang="scss">
body {
  @include md {
    background: white;
  }
}

.step {
  max-width: 412px;

  @include md {
    padding-top: 62px;
  }

  @include xs {
    max-width: 90%;
  }

  &__heading {
    @include font(1.375rem, $primary, 700);
  }

  &__rightSection {
    width: 362px;

    @include xs {
      width: 93%;
    }
  }
}

.otp {
  width: 40px;
  height: 50px;
  border-radius: 10px;
  border: 1px solid #dedede;
  margin-right: 10px;
  text-align: center;
}

.desh {
  display: flex;
  align-items: center;
  margin-right: 20px;
  margin-left: 10px;
}
</style>
